<template>
  <div class="content">
    <main class="flex-shrink-0 form-signin">
      <div class="content p-4 box-login shadow">
        <form @submit.prevent="Signup()" method="post">
          <img class="mb-0" :src="require('@/assets/img/login.png')" style="width:30%;">
          <br>
          <img class="mb-1" :src="require('@/assets/img/brand.png')" style="width:50%;">
          <h4 class="text-color-1 mb-3 fw-bold">Register</h4>
          <div class="text-start">
            <label class="text-color-1">Names</label>
            <input type="text" v-model="signup.names"  class="form-control" id="names" placeholder="Jhon Doe" required="required">
          </div>
          <div class="text-start">
            <label class="text-color-1">Email address</label>
            <input type="email" v-model="signup.email" class="form-control" id="email" placeholder="name@example.com" required="required">
          </div>
          <div class="text-start">
            <label class="text-color-1">Password</label>
            <input type="password" v-model="signup.password" class="form-control" id="password" placeholder="Password" minlength="8" required="required">
            <ul>
              <li v-bind:class="{ is_valid: PassValidate.contains_eight_characters }"><small>8 Characters</small></li>
              <li v-bind:class="{ is_valid: PassValidate.contains_number }"><small>Contains Number</small></li>
              <li v-bind:class="{ is_valid: PassValidate.contains_uppercase }"><small>Contains Uppercase</small></li>
              <li v-bind:class="{ is_valid: PassValidate.contains_special_character }"><small>Contains Special Character</small></li>
            </ul>
          </div>
           <hr>
          <button :disabled="Form.submit" class="button-1 w-100 btn btn-primary" type="submit">Register</button>
        </form>
        <div class="checkbox mb-1"></div>
        <span class="text-color-1">You have account? <router-link to="/login" class="link-color fw-bold">Login</router-link> 
        </span>
      </div>
    </main>
    <p class="mt-0 text-muted">© Work Mileage 2022</p>
  </div>
</template>

<script>
  import Api from '@/services/Api';

  export default 
  {
    data: () => (
    {
      signup: 
      {
        names: "",
        email: "",
        password: "",
      },
      response: 
      {
        message: "",
        status: "Empty"
      },
      Form: 
      {
        submit: false
      },
      PassValidate:
      {
        password: null,
        password_length: 0,
		    contains_eight_characters: false,
        contains_number: false,
        contains_uppercase: false,
		    contains_special_character: false,
        valid_password: false
      }
    }),
    methods: 
    {
      Signup() 
      {
        this.checkPassword() 
        if(this.PassValidate.valid_password  == false )
        {
          //console.log('Password is not valid')
        }

        if(this.PassValidate.valid_password  == true )
        {
          this.Form.submit = true
          Api.post("/login/signup",this.signup).then((result) => 
          {
            this.response.message =  result.data.msg
            this.response.status =  result.data.status

            if(this.response.status == "true")
            {
              this.$moshaToast( ''+this.response.message+'', { type: 'default', position:'bottom-right', transition:'zoom' })
              setTimeout( () => this.$router.push({ path: '/login'}), 5000);
              this.Form.submit = true
            }
          })
        }
      },
      checkPassword() 
      {
        this.PassValidate.password = this.signup.password
        this.PassValidate.password_length = this.PassValidate.password.length;
			  const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
			
        if (this.PassValidate.password_length >= 8) 
        {
          this.PassValidate.contains_eight_characters = true;
        } 
        else 
        {
          this.PassValidate.contains_eight_characters = false;
			  }
			
        this.PassValidate.contains_number = /\d/.test(this.PassValidate.password);
        this.PassValidate.contains_uppercase = /[A-Z]/.test(this.PassValidate.password);
			  this.PassValidate.contains_special_character = format.test(this.PassValidate.password);
      
        if (this.PassValidate.contains_eight_characters === true && this.PassValidate.contains_special_character === true && this.PassValidate.contains_uppercase === true && this.PassValidate.contains_number === true) 
        {
			    this.PassValidate.valid_password = true;			
        } 
        else 
        {
          this.PassValidate.valid_password = false;
        }
      }
    }
  }
</script>

<style>
.is_valid { color: rgba(136, 152, 170, 0.8);    text-decoration: line-through green; }
.is_valid:before { width: 100%; }

.link-color
{
    color: var(--color-three);
    text-decoration: none;
}
.content 
{
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
}
.box-login
{
    background-color: var(--color-two);  
    border-radius: 5px;  
}
.text-color-1
{
    color:var(--color-one);
}
.form-signin 
{
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.button-1
{
    background-color: var(--color-one);
    border:1px solid var(--color-one);
}
.button-1:hover
{
    background-color: var(--color-one);
    border:1px solid var(--color-one);
}

.form-signin .checkbox 
{
  font-weight: 400;
}

.form-signin input[type="text"] 
{
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: var(--color-five);
  border: 1px solid var(--color-five);
}

.form-signin input[type="email"] 
{
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: var(--color-five);
  border: 1px solid var(--color-five);
}

.form-signin input[type="password"] 
{
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: var(--color-five);
  border: 1px solid var(--color-five);
}
.bd-placeholder-img 
{
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }

  @media (min-width: 768px) 
  {
    .bd-placeholder-img-lg 
    {
      font-size: 3.5rem;
    }
  }

.h4, h4 
{
    font-size: calc(1rem + .3vw);
}
</style>
