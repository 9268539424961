<template>
     <Signup/>
</template>

<script>
// @ is an alias to /src
import Signup from '@/components/login/Signup.vue'

export default 
{

  components: 
  {
    Signup
  }
}
</script>
